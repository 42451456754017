export default [
  {
    title: "pxl.bike",
    picture: "pxlbike.svg",
    description: "🚲 Webapp made to create beautiful custom pixel bikes!",
    stack: ["react", "tailwind-css"],
    stars: 0,
    forks: 0,
    link: "https://github.com/icaroharry/pxl.bike",
    colors: ["light", "primary"],
  },
  {
    title: "electron-metamask-boilerplate",
    picture: "metamask.svg",
    description:
      "Easily create a desktop version of your dApp using Electron (Muon) + MetaMask.",
    stack: ["javascript", "electron", "muon", "blockchain", "ethereum"],
    stars: 54,
    forks: 35,
    link: "https://github.com/SwapyNetwork/electron-metamask-boilerplate",
    colors: ["yellow-300", "yellow-600"],
  },
  {
    title: "sort",
    picture: "",
    description: "Repository of sort algorithms in C and CUDA",
    stack: ["C", "CUDA", "algorithms"],
    stars: 13,
    forks: 6,
    link: "https://github.com/icaroharry/sort",
    colors: ["pink-300", "pink-600"],
  },
  {
    title: "ethereum-workshop",
    picture: "ethereum.png",
    description: "Get started with Ethereum development [pt-BR]",
    stack: ["ethereum", "javascript", "web3"],
    stars: 3,
    forks: 0,
    link: "https://github.com/icaroharry/ethereum-workshop",
    colors: ["gray-300", "gray-600"],
  },
  {
    title: "you-need-an-automatic-budget",
    picture: "money.png",
    description:
      "💸 Automatically exports expenses from Banco Inter to You Need a Budget 💸",
    stack: ["javascript", "serverless", "IFTTT", "firebase"],
    stars: 3,
    forks: 0,
    link: "https://github.com/icaroharry/you-need-an-automatic-budget",
    colors: ["green-200", "green-500"],
  },
];
